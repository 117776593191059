@import '~app/mixins';

.wrapper {
  background-color: $color-white;
  box-shadow: -1px 0 4px 0 rgba(0, 0, 0, 0.37);
  display: flex;
  flex-direction: column;
  height: 100%;

  .resultsHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 10px 12px 8px;
    border: 1px solid #eee;
    background-color: $color-white;
    position: relative;

    .left,
    .right {
      display: flex;
      align-items: center;
    }

    .left {
      .caption {
        font-size: 16px;
        color: $color-teal;
      }

      .selected {
        margin-left: 7px;
        font-size: 11px;
        font-style: italic;
        color: #6b7581;
      }

      .range {
        display: inline-block;
        color: $color-teal;
        margin-left: 5px;

        div {
          display: inline-block;
          margin: 0 3px;
        }

        input {
          border-radius: 5px;
          border: 1px solid $color-grey-light;
          color: $color-grey-dark;
          width: 62px;
          margin: 0 2px;
          padding: 3px;
        }
      }
    }

    .selectedBottom {
      margin-left: 7px;
      font-size: 11px;
      font-style: italic;
      color: #6b7581;
      position: absolute;
      bottom: 1px;
      left: 4px;
    }

    .right {
      button {
        margin-left: 10px;
        color: $color-teal;
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  .view {
    overflow-y: auto;
    flex: 1;

    .container {
      padding-left: 10px;
    }

    .content {
      height: 100%;
      overflow-y: auto;

      .item {
        position: relative;
        display: flex;
        margin-top: -1px;
        padding: 15px 15px 5px 8px;
        border-top: 1px solid #eee;
        border-bottom: 1px solid #eee;
        background-color: $color-white;
        color: $color-grey;
        font-size: 12px;

        &:first-child {
          margin-top: 0;
        }

        &.itemChecked {
          z-index: 1;
          outline-offset: -1px;
          background-color: $color-light-blue;
          outline-width: 1px;
          outline-style: solid;

          @include primary-color-outline-color();
        }

        &.bigCounter {
          .checkboxContainer {
            width: 20px;
          }

          .index {
            width: 50px;
          }

          .mask {
            width: 115px;
          }
        }

        .checkboxContainer {
          flex-grow: 0;
          width: 20px;
          margin-top: -3px;

          label {
            display: block;
          }
        }

        .checkboxContainer,
        .index,
        .mask {
          flex-shrink: 0;
        }

        .option,
        .type {
          white-space: nowrap;
        }

        .type {
          color: $color-grey-1;
        }

        .index {
          cursor: pointer;
          flex-grow: 0;
          width: 30px;
          line-height: 15px;
          text-align: center;
        }

        .image {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .mask {
            cursor: pointer;
            position: relative;
            width: 120px;
            height: 80px;
            overflow: hidden;

            .dot {
              $position: 7px;
              $size: 13px;

              position: absolute;
              top: $position;
              right: $position;
              height: $size;
              width: $size;
              border: 1px solid $color-white;
            }

            img {
              display: block;
              margin-left: auto;
              margin-right: auto;
              max-width: 100%;
            }
          }

          .type {
            margin-top: 6px;
            font-weight: bold;
            font-size: 14px;
          }
        }

        .info {
          cursor: pointer;
          flex-grow: 1;
          margin-left: 13px;

          .header,
          .footer {
            display: flex;
            justify-content: space-between;
          }

          .value {
            color: $color-grey-1;
          }

          .header {
            .name {
              cursor: pointer;
              margin-bottom: 3px;
              color: $color-teal;
              font-size: 14px;
              font-weight: 700;
            }

            .apn {
              color: #999;
            }

            .left {
              line-height: 15px;
            }

            .right {
              text-align: right;
              display: flex;
              flex-direction: column;
              align-items: flex-end;

              .value {
                margin-bottom: 6px;

                div:first-child {
                  color: $color-grey-light;
                }

                div:last-child {
                  font-weight: bold;
                }
              }

              .characteristics {
                line-height: 16px;
                max-width: 200px;

                .option {
                  display: inline-block;
                  vertical-align: top;
                  padding-left: 10px;
                }

                span {
                  padding-left: 10px;

                  &:first-child {
                    padding-left: 0;
                  }
                }
              }
            }
          }

          .footer {
            margin: 14px -10px 0 -10px;
            color: $color-grey-light;

            .field {
              padding: 0 10px;

              &:first-child {
                text-align: left;
                flex: 1 0 0;
              }

              &:last-child {
                text-align: right;
                flex: 1 0 0;
              }

              &.hidden {
                visibility: hidden;
              }

              .title {
                white-space: nowrap;
                display: block;
              }

              .value {
                font-size: 14px;
                font-weight: bold;
                display: block;
              }
            }
          }
        }
      }
    }
  }
}
